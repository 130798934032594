import React from 'react';
import './FourOFourPage.component.scss';

export default function FourOFourPage() {
  return (
    <div className="four-o-four-page">
      <h1 className="display1">404</h1>
      <p>Ne pare rau! Pagina pe care incerci sa o accesezi, nu exista.</p>
    </div>
  );
}
